import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full library-container flex flex-col items-center justify-start mx-auto my-5 gap-4"
}
const _hoisted_2 = { class: "w-full overflow-y-scroll scroll-smooth" }
const _hoisted_3 = { class: "p-2 text-left flex justify-between" }
const _hoisted_4 = { class: "px-2 text-left text-xs h-auto" }

import { UISkeleton } from '@gohighlevel/ghl-ui'
import { useStore } from 'vuex'


export default /*@__PURE__*/_defineComponent({
  __name: 'GlobalSearchShimmerV2',
  setup(__props) {

const store = useStore()

const { newMobileScreens } = {
  newMobileScreens: store.getters.newMobileScreens,
}

return (_ctx: any,_cache: any) => {
  return (_unref(newMobileScreens))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (n) => {
            return _createElementVNode("div", {
              key: n,
              class: "searchList flex flex-col my-2 py-2 border-gray-50 rounded-lg bg-gray-50 hover:bg-gray-100 leading-5 gap-4"
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_unref(UISkeleton), {
                  height: "16px",
                  width: "60%",
                  sharp: false
                }),
                _createVNode(_unref(UISkeleton), {
                  height: "20px",
                  width: "20px",
                  sharp: true
                })
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_unref(UISkeleton), {
                  height: "12px",
                  width: "90%",
                  sharp: false
                })
              ])
            ])
          }), 64))
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})