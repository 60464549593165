import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-11/12 library-container flex flex-col items-center justify-start mx-auto my-5 gap-4"
}
const _hoisted_2 = { class: "w-full" }
const _hoisted_3 = { class: "w-full my-4 flex gap-4" }
const _hoisted_4 = { class: "w-full overflow-y-scroll scroll-smooth" }
const _hoisted_5 = { class: "w-full flex items-center justify-between gap-4 my-4 hover:bg-slate-50 cursor-pointer" }
const _hoisted_6 = { class: "w-3/5 min-w-36 h-28 xs:h-32 sm:h-48 md:h-60 rounded-md" }
const _hoisted_7 = { class: "h-full w-3/4 flex flex-col items-start justify-around gap-1" }

import { UISkeleton } from '@gohighlevel/ghl-ui'
import { useStore } from 'vuex'


export default /*@__PURE__*/_defineComponent({
  __name: 'CoursesShimmerV2',
  setup(__props) {

const store = useStore()

const { newMobileScreens } = {
  newMobileScreens: store.getters.newMobileScreens,
}

return (_ctx: any,_cache: any) => {
  return (_unref(newMobileScreens))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (n) => {
              return _createElementVNode("div", {
                key: n,
                class: "w-64 h-60 flex flex-col flex-shrink-0 items-start gap-1 justify-around"
              }, [
                _createVNode(_unref(UISkeleton), {
                  height: "66.67%",
                  width: "100%",
                  sharp: false
                }),
                _createVNode(_unref(UISkeleton), {
                  height: "16px",
                  width: "20%",
                  sharp: false
                }),
                _createVNode(_unref(UISkeleton), {
                  height: "16px",
                  width: "35%",
                  sharp: false
                })
              ])
            }), 64))
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (n) => {
            return _createElementVNode("div", {
              key: n,
              class: "w-full"
            }, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_unref(UISkeleton), {
                    height: "100%",
                    width: "100%",
                    sharp: false
                  })
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_unref(UISkeleton), {
                    height: "16px",
                    width: "75%",
                    sharp: false
                  }),
                  _createVNode(_unref(UISkeleton), {
                    height: "12px",
                    width: "100% ",
                    sharp: false
                  }),
                  _createVNode(_unref(UISkeleton), {
                    height: "12px",
                    width: "50%",
                    sharp: false
                  })
                ])
              ])
            ])
          }), 64))
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})