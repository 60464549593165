import Category from '@/models/Category'
import Post from '@/models/Post'
import Product from '@/models/Product'
import UpsellOffer from '@/models/UpsellOffer'
import http from '../../http-common'

export default class UserPurchaseService {
  protected subdomain = 'user-purchase'
  private readonly locationId: string

  constructor(locationId: string) {
    this.locationId = locationId
  }

  private get endpoint() {
    return `/locations/${this.locationId}/${this.subdomain}`
  }

  findAll(params?: any): Promise<[]> {
    return http
      .get(`${this.endpoint}`, {
        params,
      })
      .then((res) => res.data)
  }

  getAllProducts(params?: any): Promise<Array<Product>> {
    return http
      .get(`${this.endpoint}/get-all-products`, {
        params,
      })
      .then((res) => {
        const products = res.data.map((d) => new Product(d))
        return products.sort(
          (a: Product, b: Product) => a.libraryOrder - b.libraryOrder
        )
      })
  }

  checkIfProductBought(productId: string, source: string): Promise<any> {
    return http
      .get(
        `${this.endpoint}/check-product-purchase/${productId}?source=${source}`
      )
      .then((res) => res.data)
  }

  async getCategories(params?: any): Promise<Category[]> {
    const {
      data: { categories: _categories, availability, hasProductAccess },
    } = await http.get(`${this.endpoint}/categories`, { params })
    const categories = _categories.map((c: any) => {
      const cat = new Category({ ...c, hasProductAccess })

      if (c.posts && c.posts.length) {
        cat.posts = c.posts.map((p: any) => new Post(p))
      }
      cat.available = availability[cat.id]
      return cat
    })

    return categories
  }

  async getBundleOffers(): Promise<UpsellOffer[]> {
    const { data } = await http.get(`${this.endpoint}/bundle-offers`)
    return data
  }

  async getCategory(id: string, params?: any): Promise<Category> {
    const {
      data: { category: _category, available, isLocked, hasProductAccess },
    } = await http.get(`${this.endpoint}/categories/${id}`, {
      params,
    })

    const category = new Category({ ..._category, hasProductAccess })
    category.available = available
    category.isLocked = isLocked

    if (_category.posts && _category.posts.length) {
      category.posts = _category.posts.map((p: any) => new Post(p))
    }

    return category
  }
}
