<template>
  <VueRecaptcha
    @expired="onExpired"
    @verify="onVerify"
    sitekey="6LfcbMseAAAAAI-EJoB-lUh7_TJaYloLbcbmnhEO"
    v-visibility-observer="loadScript"
    class="flex justify-center g-recaptcha mb-2"
  />
</template>

<script lang="ts" setup>
import { VueRecaptcha } from 'vue-recaptcha'
import { onLoad } from '../../../helper/captcha.helper'

const emit = defineEmits(['verify', 'expired'])

const loadScript = () => {
  if (process.client) {
    onLoad(() => {
      const scriptTag = document.createElement('script')
      scriptTag.type = 'text/javascript'
      scriptTag.async = true
      scriptTag.src =
        'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit'
      document.head.appendChild(scriptTag)
    })
  }
}

const onVerify = (event) => {
  emit('verify', event)
}
const onExpired = (event) => {
  emit('expired', event)
}
</script>
<style>
.g-recaptcha {
  transform: scale(0.96);
  -webkit-transform: scale(0.96);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  padding-left: 1.2rem;
}
</style>
