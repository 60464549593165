<template>
  <div>
    <div class="my-auto w-96 relative" ref="searchContainerRef">
      <div class="flex rounded-full px-2">
        <UIInput
          id="search"
          class="search-bar"
          :model-value="searchText"
          @update:model-value="handleSearchInput"
          :aria-label="t('search.placeholder')"
          name="search"
          type="text"
          :round="true"
          :maxlength="64"
          :placeholder="t('search.placeholder')"
          @click="() => updateSearchFocus(true)"
        >
          <template #prefix>
            <SearchSmIcon class="w-4 h-4 text-gray-500" id="search-icon" />
          </template>
        </UIInput>
      </div>
      <div
        v-if="showSearchDropdown"
        class="w-full bg-white border rounded-lg mt-2 absolute top-9 left-0 h-96 z-50 overflow-y-auto shadow-xl"
      >
        <div class="w-full h-full" id="searchList">
          <div
            v-if="searchData.length || loading || isUserTyping"
            id="optionList"
            class="block"
          >
            <div
              class="searchList flex flex-col py-2 text-gray-700 cursor-pointer px-4 hover:bg-neo-classic-hover-bg"
              v-for="(option, idx) in searchData"
              :class="idx !== searchData.length - 1 ? 'border-b' : ''"
              :key="option.typeId"
              @click.stop.prevent="changeRoute(option)"
              role="button"
              :aria-label="option.title"
            >
              <div class="w-full flex items-center justify-between">
                <UITextSmMedium
                  class="text-gray-900 mb-2 custom-word-break"
                  id="search-lesson-title"
                >
                  {{
                    option.title.length > 50
                      ? `${option.title.substring(0, 40)}...`
                      : option.title
                  }}
                </UITextSmMedium>
                <img
                  class="text-gray-700 h-5"
                  :src="searchIcons[option.type]"
                  :alt="t('universalSearch.productIcon')"
                />
              </div>
              <UITextSmRegular
                v-if="option.description"
                class="px-0 h-auto mb-2 custom-word-break text-gray-600 text-ellipsis whitespace-nowrap overflow-hidden"
                id="search-lesson-description"
                >{{
                  getTextContentFromHTML(option.description)
                }}</UITextSmRegular
              >
              <UITextSmRegular
                v-if="
                  option.type !== 'product' &&
                  productTitleMapping[option.productId]
                "
                class="product-title px-0 text-neo-classic-secondary-text custom-word-break text-ellipsis whitespace-nowrap overflow-hidden"
                id="product-title"
              >
                {{ productTitleMapping[option.productId] }}
              </UITextSmRegular>
            </div>
            <div
              class="px-4 py-2 flex justify-center items-center text-gray-700"
              ref="loadMoreRef"
              v-show="!allDataLoaded"
            >
              <span class="inline-flex h-6 w-6" v-if="loading || isUserTyping">
                <UISpinner size="small" />
              </span>
            </div>
          </div>
          <div
            v-else-if="recentSearches.length && !searchText"
            class="p-2 text-gray-700"
            id="recent-searches"
          >
            <div class="text-left">{{ t('search.recentSearches') }}</div>
            <div
              class="searchList flex flex-col pr-6 rounded py-2 text-gray-700 cursor-pointer hover:bg-neo-classic-hover-bg leading-5"
              v-for="(option, index) in recentSearches"
              :key="index"
              @click.stop.prevent="() => searchContent(option)"
              role="button"
              :aria-label="option"
            >
              <div
                class="px-2 text-left recent-search-option flex items-center justify-between group"
              >
                <div class="grid grid-cols-[auto_1fr] gap-2 items-center">
                  <ClockIcon class="w-5 h-5 mr-2" />
                  <UITextSmRegular
                    class="text-ellipsis whitespace-nowrap overflow-hidden"
                  >
                    {{ option }}
                  </UITextSmRegular>
                </div>
                <UIButton
                  class="invisible group-hover:visible"
                  @click.stop.prevent="clearRecentSearch(option)"
                  :id="`clear-recent-search-${index}`"
                  type="default"
                  size="medium"
                  :ghost="false"
                  :quaternary="true"
                  :circle="true"
                  :text="false"
                >
                  <XCloseIcon class="w-3 h-3" />
                </UIButton>
              </div>
            </div>
          </div>
          <div
            v-else-if="!isSearchDataAvailable && !isUserTyping"
            class="w-full h-full flex items-center justify-center px-2 py-2 text-sm leading-5 text-gray-700 text-center"
          >
            <UIEmpty
              id="no-search-results"
              :title="
                searchText.length < SEARCH_CHAR_LIMIT
                  ? t('search.charLimit')
                  : t('search.noResultsFound')
              "
              :description="t('search.emptyText')"
              :icon="SearchSmIcon"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted, onUnmounted, computed } from 'vue'
import { UniversalSearchService } from '../../services'
import { getLimitedDescription, getTextContentFromHTML } from '@/helper'
import {
  UITextSmMedium,
  UITextSmRegular,
  UIInput,
  UIEmpty,
  UIButton,
} from '@gohighlevel/ghl-ui'
import {
  SearchSmIcon,
  ClockIcon,
  XCloseIcon,
} from '@gohighlevel/ghl-icons/24/outline'
import UISpinner from '@/components/common/UISpinner.vue'
import { useRouter, useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { notify } from '@/helper'
import { useNotification } from '@gohighlevel/ghl-ui'
import {
  productIcon,
  folderIcon,
  fileIcon,
  SEARCH_CHAR_LIMIT,
} from '@/helper/constants'

const { t } = useI18n()

const router = useRouter()
const route = useRoute()
const notification = useNotification()

let searchDebounceTimer = null

const searchIcons = {
  product: productIcon,
  category: folderIcon,
  post: fileIcon,
}
// Refs
const searchText = ref('')
const limit = ref(10)
const page = ref(1)
const totalSearchCount = ref(0)
const searchData = ref([])
const loading = ref(false)
const isSearchDataAvailable = ref(true)
const recentSearches = ref([])
const searchIds = ref([])
const productTitleMapping = ref({})
const observer = ref(null)
const allDataLoaded = ref(false)
const loadMoreRef = ref(null)
const searchContainerRef = ref(null)
const isUserTyping = ref(false)
const searchInFocus = ref(false)

const showSearchDropdown = computed(() => {
  return (
    (searchInFocus.value && searchText.value.length > 0) ||
    (searchInFocus.value && recentSearches.value.length > 0)
  )
})

function updateSearchFocus(value: boolean) {
  searchInFocus.value = value
}

const handleSearchInput = (value: string) => {
  searchText.value = value
  searchDebounce(value)
}

const searchDebounce = (value: string) => {
  isUserTyping.value = true
  clearTimeout(searchDebounceTimer)
  searchDebounceTimer = setTimeout(() => {
    searchContent(value)
  }, 500)
}

async function searchFullText() {
  try {
    isUserTyping.value = false
    loading.value = true

    const payload = {
      searchKey: searchText.value,
      pageLimit: limit.value,
      pageNumber: page.value,
    }
    const newData = await UniversalSearchService.searchItems(payload)
    const productIds = []
    // Only set allDataLoaded if we received fewer items than the limit
    if (newData.length) {
      page.value = page.value + 1
      for (const data of newData) {
        if (data.productId && !searchIds.value.includes(data.typeId)) {
          productIds.push(data.productId)
          searchIds.value.push(data.typeId)
        }
        data.description = getLimitedDescription(data.description, 65)
      }
      searchData.value = [...searchData.value, ...newData]
      await fetchProductTitle(productIds)
    }
    allDataLoaded.value = totalSearchCount.value === searchData.value.length
    isSearchDataAvailable.value = searchData.value.length > 0
  } catch (error) {
    console.error('Error fetching search data:', error)
    notify(notification, {
      type: 'error',
      title: t('search.error.unableToLoad'),
    })
  } finally {
    loading.value = false
  }
}

async function searchContent(option?: string) {
  try {
    resetData()
    if (option) {
      searchText.value = option
      // Reset the observer before starting a new search
      if (observer.value) {
        observer.value.disconnect()
      }
      await Promise.allSettled([searchFullText(), searchCount(option)])
      // Reinitialize the observer after search
      if (loadMoreRef.value && !allDataLoaded.value) {
        observer.value = new IntersectionObserver(handleIntersection, {
          threshold: 1.0,
        })
        observer.value.observe(loadMoreRef.value)
      }
    }
  } catch (error) {
    console.error('Error while searching content:', error)
    notify(notification, {
      type: 'error',
      title: t('search.error.unableToLoad'),
    })
  } finally {
    isUserTyping.value = false
  }
}

function resetData() {
  searchData.value = []
  page.value = 1
  searchIds.value = []
  productTitleMapping.value = {}
  allDataLoaded.value = false
}

async function searchCount(searchKey: string) {
  try {
    const response = await UniversalSearchService.searchCount({
      searchKey: searchKey,
    })
    totalSearchCount.value = response?.totalCount || 0
  } catch (err) {
    console.error('Error fetching search count:', err)
  }
}

async function fetchProductTitle(productIds: Array<string>) {
  try {
    if (productIds.length) {
      const response = await UniversalSearchService.getProductTitles({
        productIds,
      })
      productTitleMapping.value = { ...productTitleMapping.value, ...response }
    }
  } catch (error) {
    console.error('Error fetching product title:', error)
  }
}

function clearRecentSearch(option: string) {
  const recentSearchItems =
    JSON.parse(window.localStorage.getItem('searchItems')) || []
  if (recentSearchItems.includes(option)) {
    recentSearchItems.splice(recentSearchItems.indexOf(option), 1)
    window.localStorage.setItem(
      'searchItems',
      JSON.stringify(recentSearchItems.slice(0, 5))
    )
  }
  updateRecentSearches()
}

function changeRoute(option: any) {
  const recentSearchItems =
    JSON.parse(window.localStorage.getItem('searchItems')) || []
  if (!recentSearchItems.includes(searchText.value)) {
    recentSearchItems.unshift(searchText.value)
    window.localStorage.setItem(
      'searchItems',
      JSON.stringify(recentSearchItems.slice(0, 5))
    )
  }

  router.push({
    name:
      option.type === 'product'
        ? 'product-overview'
        : option.type === 'category'
        ? 'category-overview'
        : 'post-overview',
    params: {
      id: option.productId,
      category_id: option.categoryId,
      post_id: option.typeId,
    },
    query: route.query,
  })

  searchText.value = ''
  resetData()
  updateSearchFocus(false)
  updateRecentSearches()
}

async function nextSearchData() {
  if (!loading.value && !allDataLoaded.value) {
    await searchFullText()
  }
}

async function handleIntersection(entries: IntersectionObserverEntry[]) {
  if (entries.length > 0) {
    const { isIntersecting } = entries[0]
    if (isIntersecting && !loading.value && !allDataLoaded.value) {
      await nextSearchData()
    }
  }
}

async function handleClickOutside(event: MouseEvent) {
  if (
    searchContainerRef.value &&
    !searchContainerRef.value.contains(event.target)
  ) {
    searchText.value = ''
    resetData()
    updateSearchFocus(false)
  }
}

function updateRecentSearches() {
  recentSearches.value =
    JSON.parse(window.localStorage.getItem('searchItems')) || []
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
  updateRecentSearches()
  observer.value = new IntersectionObserver(handleIntersection, {
    threshold: 1.0,
  })
  if (loadMoreRef.value) {
    observer.value.observe(loadMoreRef.value)
  }
})

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside)
  if (observer.value) {
    observer.value.disconnect()
  }
})
</script>

<style scoped>
.custom-word-break {
  word-break: break-word;
}
</style>
