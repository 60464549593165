<script lang="ts" setup>
import { UISkeleton } from '@gohighlevel/ghl-ui'
import { useStore } from 'vuex'

const store = useStore()

const { newMobileScreens } = {
  newMobileScreens: store.getters.newMobileScreens,
}
</script>

<template>
  <div
    v-if="newMobileScreens"
    class="w-full library-container flex flex-col items-center justify-start mx-auto my-5 gap-4"
  >
    <div class="w-full overflow-y-scroll scroll-smooth">
      <!-- Render Skeleton loaders mimicking the structure of searchList items -->
      <div
        v-for="n in 3"
        :key="n"
        class="searchList flex flex-col my-2 py-2 border-gray-50 rounded-lg bg-gray-50 hover:bg-gray-100 leading-5 gap-4"
      >
        <div class="p-2 text-left flex justify-between">
          <!-- Title Skeleton -->
          <UISkeleton height="16px" width="60%" :sharp="false" />
          <!-- Icon Skeleton -->
          <UISkeleton height="20px" width="20px" :sharp="true" />
        </div>
        <div class="px-2 text-left text-xs h-auto">
          <!-- Description Skeleton -->
          <UISkeleton height="12px" width="90%" :sharp="false" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.searchList {
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}
</style>
