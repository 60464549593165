import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex rounded-full px-2" }
const _hoisted_2 = {
  key: 0,
  class: "w-full bg-white border rounded-lg mt-2 absolute top-9 left-0 h-96 z-50 overflow-y-auto shadow-xl"
}
const _hoisted_3 = {
  class: "w-full h-full",
  id: "searchList"
}
const _hoisted_4 = {
  key: 0,
  id: "optionList",
  class: "block"
}
const _hoisted_5 = ["onClick", "aria-label"]
const _hoisted_6 = { class: "w-full flex items-center justify-between" }
const _hoisted_7 = ["src", "alt"]
const _hoisted_8 = {
  key: 0,
  class: "inline-flex h-6 w-6"
}
const _hoisted_9 = {
  key: 1,
  class: "p-2 text-gray-700",
  id: "recent-searches"
}
const _hoisted_10 = { class: "text-left" }
const _hoisted_11 = ["onClick", "aria-label"]
const _hoisted_12 = { class: "px-2 text-left recent-search-option flex items-center justify-between group" }
const _hoisted_13 = { class: "grid grid-cols-[auto_1fr] gap-2 items-center" }
const _hoisted_14 = {
  key: 2,
  class: "w-full h-full flex items-center justify-center px-2 py-2 text-sm leading-5 text-gray-700 text-center"
}

import { ref, onMounted, onUnmounted, computed } from 'vue'
import { UniversalSearchService } from '../../services'
import { getLimitedDescription, getTextContentFromHTML } from '@/helper'
import {
  UITextSmMedium,
  UITextSmRegular,
  UIInput,
  UIEmpty,
  UIButton,
} from '@gohighlevel/ghl-ui'
import {
  SearchSmIcon,
  ClockIcon,
  XCloseIcon,
} from '@gohighlevel/ghl-icons/24/outline'
import UISpinner from '@/components/common/UISpinner.vue'
import { useRouter, useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { notify } from '@/helper'
import { useNotification } from '@gohighlevel/ghl-ui'
import {
  productIcon,
  folderIcon,
  fileIcon,
  SEARCH_CHAR_LIMIT,
} from '@/helper/constants'


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchBar',
  setup(__props) {

const { t } = useI18n()

const router = useRouter()
const route = useRoute()
const notification = useNotification()

let searchDebounceTimer = null

const searchIcons = {
  product: productIcon,
  category: folderIcon,
  post: fileIcon,
}
// Refs
const searchText = ref('')
const limit = ref(10)
const page = ref(1)
const totalSearchCount = ref(0)
const searchData = ref([])
const loading = ref(false)
const isSearchDataAvailable = ref(true)
const recentSearches = ref([])
const searchIds = ref([])
const productTitleMapping = ref({})
const observer = ref(null)
const allDataLoaded = ref(false)
const loadMoreRef = ref(null)
const searchContainerRef = ref(null)
const isUserTyping = ref(false)
const searchInFocus = ref(false)

const showSearchDropdown = computed(() => {
  return (
    (searchInFocus.value && searchText.value.length > 0) ||
    (searchInFocus.value && recentSearches.value.length > 0)
  )
})

function updateSearchFocus(value: boolean) {
  searchInFocus.value = value
}

const handleSearchInput = (value: string) => {
  searchText.value = value
  searchDebounce(value)
}

const searchDebounce = (value: string) => {
  isUserTyping.value = true
  clearTimeout(searchDebounceTimer)
  searchDebounceTimer = setTimeout(() => {
    searchContent(value)
  }, 500)
}

async function searchFullText() {
  try {
    isUserTyping.value = false
    loading.value = true

    const payload = {
      searchKey: searchText.value,
      pageLimit: limit.value,
      pageNumber: page.value,
    }
    const newData = await UniversalSearchService.searchItems(payload)
    const productIds = []
    // Only set allDataLoaded if we received fewer items than the limit
    if (newData.length) {
      page.value = page.value + 1
      for (const data of newData) {
        if (data.productId && !searchIds.value.includes(data.typeId)) {
          productIds.push(data.productId)
          searchIds.value.push(data.typeId)
        }
        data.description = getLimitedDescription(data.description, 65)
      }
      searchData.value = [...searchData.value, ...newData]
      await fetchProductTitle(productIds)
    }
    allDataLoaded.value = totalSearchCount.value === searchData.value.length
    isSearchDataAvailable.value = searchData.value.length > 0
  } catch (error) {
    console.error('Error fetching search data:', error)
    notify(notification, {
      type: 'error',
      title: t('search.error.unableToLoad'),
    })
  } finally {
    loading.value = false
  }
}

async function searchContent(option?: string) {
  try {
    resetData()
    if (option) {
      searchText.value = option
      // Reset the observer before starting a new search
      if (observer.value) {
        observer.value.disconnect()
      }
      await Promise.allSettled([searchFullText(), searchCount(option)])
      // Reinitialize the observer after search
      if (loadMoreRef.value && !allDataLoaded.value) {
        observer.value = new IntersectionObserver(handleIntersection, {
          threshold: 1.0,
        })
        observer.value.observe(loadMoreRef.value)
      }
    }
  } catch (error) {
    console.error('Error while searching content:', error)
    notify(notification, {
      type: 'error',
      title: t('search.error.unableToLoad'),
    })
  } finally {
    isUserTyping.value = false
  }
}

function resetData() {
  searchData.value = []
  page.value = 1
  searchIds.value = []
  productTitleMapping.value = {}
  allDataLoaded.value = false
}

async function searchCount(searchKey: string) {
  try {
    const response = await UniversalSearchService.searchCount({
      searchKey: searchKey,
    })
    totalSearchCount.value = response?.totalCount || 0
  } catch (err) {
    console.error('Error fetching search count:', err)
  }
}

async function fetchProductTitle(productIds: Array<string>) {
  try {
    if (productIds.length) {
      const response = await UniversalSearchService.getProductTitles({
        productIds,
      })
      productTitleMapping.value = { ...productTitleMapping.value, ...response }
    }
  } catch (error) {
    console.error('Error fetching product title:', error)
  }
}

function clearRecentSearch(option: string) {
  const recentSearchItems =
    JSON.parse(window.localStorage.getItem('searchItems')) || []
  if (recentSearchItems.includes(option)) {
    recentSearchItems.splice(recentSearchItems.indexOf(option), 1)
    window.localStorage.setItem(
      'searchItems',
      JSON.stringify(recentSearchItems.slice(0, 5))
    )
  }
  updateRecentSearches()
}

function changeRoute(option: any) {
  const recentSearchItems =
    JSON.parse(window.localStorage.getItem('searchItems')) || []
  if (!recentSearchItems.includes(searchText.value)) {
    recentSearchItems.unshift(searchText.value)
    window.localStorage.setItem(
      'searchItems',
      JSON.stringify(recentSearchItems.slice(0, 5))
    )
  }

  router.push({
    name:
      option.type === 'product'
        ? 'product-overview'
        : option.type === 'category'
        ? 'category-overview'
        : 'post-overview',
    params: {
      id: option.productId,
      category_id: option.categoryId,
      post_id: option.typeId,
    },
    query: route.query,
  })

  searchText.value = ''
  resetData()
  updateSearchFocus(false)
  updateRecentSearches()
}

async function nextSearchData() {
  if (!loading.value && !allDataLoaded.value) {
    await searchFullText()
  }
}

async function handleIntersection(entries: IntersectionObserverEntry[]) {
  if (entries.length > 0) {
    const { isIntersecting } = entries[0]
    if (isIntersecting && !loading.value && !allDataLoaded.value) {
      await nextSearchData()
    }
  }
}

async function handleClickOutside(event: MouseEvent) {
  if (
    searchContainerRef.value &&
    !searchContainerRef.value.contains(event.target)
  ) {
    searchText.value = ''
    resetData()
    updateSearchFocus(false)
  }
}

function updateRecentSearches() {
  recentSearches.value =
    JSON.parse(window.localStorage.getItem('searchItems')) || []
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
  updateRecentSearches()
  observer.value = new IntersectionObserver(handleIntersection, {
    threshold: 1.0,
  })
  if (loadMoreRef.value) {
    observer.value.observe(loadMoreRef.value)
  }
})

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside)
  if (observer.value) {
    observer.value.disconnect()
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "my-auto w-96 relative",
      ref_key: "searchContainerRef",
      ref: searchContainerRef
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_unref(UIInput), {
          id: "search",
          class: "search-bar",
          "model-value": searchText.value,
          "onUpdate:modelValue": handleSearchInput,
          "aria-label": _unref(t)('search.placeholder'),
          name: "search",
          type: "text",
          round: true,
          maxlength: 64,
          placeholder: _unref(t)('search.placeholder'),
          onClick: _cache[0] || (_cache[0] = () => updateSearchFocus(true))
        }, {
          prefix: _withCtx(() => [
            _createVNode(_unref(SearchSmIcon), {
              class: "w-4 h-4 text-gray-500",
              id: "search-icon"
            })
          ]),
          _: 1
        }, 8, ["model-value", "aria-label", "placeholder"])
      ]),
      (showSearchDropdown.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (searchData.value.length || loading.value || isUserTyping.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(searchData.value, (option, idx) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass(["searchList flex flex-col py-2 text-gray-700 cursor-pointer px-4 hover:bg-neo-classic-hover-bg", idx !== searchData.value.length - 1 ? 'border-b' : '']),
                        key: option.typeId,
                        onClick: _withModifiers(($event: any) => (changeRoute(option)), ["stop","prevent"]),
                        role: "button",
                        "aria-label": option.title
                      }, [
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_unref(UITextSmMedium), {
                            class: "text-gray-900 mb-2 custom-word-break",
                            id: "search-lesson-title"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(option.title.length > 50
                      ? `${option.title.substring(0, 40)}...`
                      : option.title), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createElementVNode("img", {
                            class: "text-gray-700 h-5",
                            src: searchIcons[option.type],
                            alt: _unref(t)('universalSearch.productIcon')
                          }, null, 8, _hoisted_7)
                        ]),
                        (option.description)
                          ? (_openBlock(), _createBlock(_unref(UITextSmRegular), {
                              key: 0,
                              class: "px-0 h-auto mb-2 custom-word-break text-gray-600 text-ellipsis whitespace-nowrap overflow-hidden",
                              id: "search-lesson-description"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(getTextContentFromHTML)(option.description)), 1)
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true),
                        (
                  option.type !== 'product' &&
                  productTitleMapping.value[option.productId]
                )
                          ? (_openBlock(), _createBlock(_unref(UITextSmRegular), {
                              key: 1,
                              class: "product-title px-0 text-neo-classic-secondary-text custom-word-break text-ellipsis whitespace-nowrap overflow-hidden",
                              id: "product-title"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(productTitleMapping.value[option.productId]), 1)
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true)
                      ], 10, _hoisted_5))
                    }), 128)),
                    _withDirectives(_createElementVNode("div", {
                      class: "px-4 py-2 flex justify-center items-center text-gray-700",
                      ref_key: "loadMoreRef",
                      ref: loadMoreRef
                    }, [
                      (loading.value || isUserTyping.value)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                            _createVNode(UISpinner, { size: "small" })
                          ]))
                        : _createCommentVNode("", true)
                    ], 512), [
                      [_vShow, !allDataLoaded.value]
                    ])
                  ]))
                : (recentSearches.value.length && !searchText.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, _toDisplayString(_unref(t)('search.recentSearches')), 1),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(recentSearches.value, (option, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "searchList flex flex-col pr-6 rounded py-2 text-gray-700 cursor-pointer hover:bg-neo-classic-hover-bg leading-5",
                          key: index,
                          onClick: _withModifiers(() => searchContent(option), ["stop","prevent"]),
                          role: "button",
                          "aria-label": option
                        }, [
                          _createElementVNode("div", _hoisted_12, [
                            _createElementVNode("div", _hoisted_13, [
                              _createVNode(_unref(ClockIcon), { class: "w-5 h-5 mr-2" }),
                              _createVNode(_unref(UITextSmRegular), { class: "text-ellipsis whitespace-nowrap overflow-hidden" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(option), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _createVNode(_unref(UIButton), {
                              class: "invisible group-hover:visible",
                              onClick: _withModifiers(($event: any) => (clearRecentSearch(option)), ["stop","prevent"]),
                              id: `clear-recent-search-${index}`,
                              type: "default",
                              size: "medium",
                              ghost: false,
                              quaternary: true,
                              circle: true,
                              text: false
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(XCloseIcon), { class: "w-3 h-3" })
                              ]),
                              _: 2
                            }, 1032, ["onClick", "id"])
                          ])
                        ], 8, _hoisted_11))
                      }), 128))
                    ]))
                  : (!isSearchDataAvailable.value && !isUserTyping.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                        _createVNode(_unref(UIEmpty), {
                          id: "no-search-results",
                          title: 
                searchText.value.length < _unref(SEARCH_CHAR_LIMIT)
                  ? _unref(t)('search.charLimit')
                  : _unref(t)('search.noResultsFound')
              ,
                          description: _unref(t)('search.emptyText'),
                          icon: _unref(SearchSmIcon)
                        }, null, 8, ["title", "description", "icon"])
                      ]))
                    : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true)
    ], 512)
  ]))
}
}

})