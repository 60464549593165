<template>
  <div>
    <div id="offer-stripe-payment" class="px-2">
      <div class="flex mb-4">
        <div class="flex-grow">
          <label
            for="stripe-card-number"
            class="text-gray-700 text-sm text-left block"
            >{{ this.t('payment.stripe.card_number') }}</label
          >
          <div id="stripe-card-number" class="stripe-input"></div>
        </div>
      </div>
      <div class="flex mb-4">
        <div class="flex-auto mr-4">
          <label
            for="stripe-card-expiry"
            class="text-gray-700 text-sm text-left block"
            >{{ this.t('payment.stripe.expiry_date') }}</label
          >
          <div id="stripe-card-expiry" class="stripe-input"></div>
        </div>
        <div class="flex-auto">
          <label
            for="stripe-card-cvc"
            class="text-gray-700 text-sm text-left block"
            >{{ this.t('payment.stripe.cvc') }}</label
          >
          <div id="stripe-card-cvc" class="stripe-input"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, toRaw } from 'vue'
import { useI18n } from 'vue-i18n'
import { loadStripe } from '@stripe/stripe-js/pure'

export default defineComponent({
  name: 'StripeCardElement',
  setup() {
    const { t } = useI18n()
    return { t }
  },
  props: {
    location: {
      type: Object,
    },
    stripeCardError: {
      type: String,
    },
  },
  async mounted() {
    await this.configureStripe()
  },
  methods: {
    async configureStripe() {
      const { stripe: publishableKey, stripeAccountId } = toRaw(this.location)
      const stripe = await loadStripe(publishableKey, {
        stripeAccount: stripeAccountId,
      })
      if (!stripe) {
        this.$emit(
          'stripe-card-error',
          this.t('payment.stripe.errors.load_failed')
        )
        return
      }

      this.$emit('set-stripe', stripe)
      const stripeElements = stripe.elements()
      const cardExpiry = stripeElements.create('cardExpiry', {
        classes: {
          focus: 'focus',
          empty: 'empty',
          invalid: 'invalid',
        },
      })
      cardExpiry.mount('#stripe-card-expiry')
      cardExpiry.on('change', this.handleCardError)
      const cardCvc = stripeElements.create('cardCvc', {
        classes: {
          focus: 'focus',
          empty: 'empty',
          invalid: 'invalid',
        },
      })
      cardCvc.mount('#stripe-card-cvc')
      cardCvc.on('change', this.handleCardError)
      const cardNumber = stripeElements.create('cardNumber', {
        showIcon: true,
        classes: {
          focus: 'focus',
          empty: 'empty',
          invalid: 'invalid',
        },
      })
      cardNumber.mount('#stripe-card-number')
      cardNumber.on('change', this.handleCardError)
      this.$emit('stripe-elements', stripeElements)
    },
    handleCardError(event: any) {
      this.$emit(
        'stripe-card-error',
        event?.error?.message
          ? this.t('payment.stripe.errors.incomplete_info')
          : ''
      )
    },
  },
})
</script>
<style scoped>
.stripe-input {
  background: #ffffff;
  border: 1px solid #bcc7d3;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px;
}
.stripe-input.focus {
  border-color: #38a0db;
}

.stripe-input.invalid {
  border-color: #fa755a;
}

#offer-stripe-payment input:focus {
  outline: none !important;
  border: 1px solid #38a0db !important;
}

#stripe-error {
  color: #fa755a;
}
</style>
